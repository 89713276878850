export const defaultDateFormat = "M/d/yyyy";

export enum FileType {
  Logo = "logo",
  ProfilePicture = "profile_picture",
  ACRA = "acra",
  PitchDeck = "pitch",
  SummaryAndOther = "summary_and_other",
  ProductPhoto = "product_photo",
  DealDeck = "deal_deck",
}
export interface InnovatorFile {
  created: string;
  id: number;
  publicUrl: string;
  secureUrl: string;
  type: FileType;
  productId: number;
  companyId: number;
  displayOrder?: number;
}

export interface Diligence {
  updated: string;
  lastUpdatedBy: User;
  raisingAmount: string;
  roundOpen: string;
  stockOrNote: string;
  isRoundTranched: string;
  leadInvestor: string;
  significantInvestors: string;
  amountNeededToClose: string;
  priorConvertibleNote: string;
  anticipatedClosing: string;
  unmetNeed: string;
  productCapabilities: string;
  teamExpertise: string;
  manufacturingPlan: string;
  manufacturingPartnersAndSupplierAgreements: string;
  COGSAndMargin: string;
  businessModel: string[];
  launchTiming: string;
  pricingStrategyAndAssumptions: string;
  haveCustomers: string;
  distributorAgreementsAndSalesChannels: string;
  generatingRevenueYesNo: string;
  servicesRevenue: string;
  secondReleasePlan: string;
  overallStrength: string;
  execution: string;
  traction: string;
  notableAwards: string;
  keyAccomplishments: string;
  productDeliveryProof: string;
  academicIndustryPartners: string;
  patentsToFile: string;
  patentsToFileDescription: string;
  patentsFiled: string;
  patentsFiledDescription: string;
  patentsIssued: string;
  patentsIssuedDescription: string;
  ftoAnalysis: string;
  summary: string;
  fdaPreSubmissionMeetingDate: string;
  fdaSubmissionDate: string;
  fdaApprovalDate: string;
  ceMarkSubmissionDate: string;
  regulatoryApprovalStatus: string;
  businessModelDetail: string;
  submitted: string;
}

export enum RoleOptions {
  Admin = "admin",
  Company = "company",
  Investor = "investor",
  Judge = "judge",
  Mentor = "mentor",
  Partner = "partner",
  Reviewer = "reviewer",
  Ecosystem = "ecosystem",
}

export interface Note {
  id: number;
  companyId: number;
  note: string;
  created: string;
  updated: string;
  lastUpdatedBy: User;
}

export interface User {
  role: RoleOptions;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  city: string;
  state: string;
  zipcode: string;
  updated: string;
  country: string;
  id: number;
  companyId: number;
  profileImageUrl: string;
  linkedInUrl: string;
  phoneNumber: string;
  shortBio: string;
  headline: string;
  slackId: string;
  openRoundsAccess: boolean;
  emailVerified: boolean;
  invalidEmail: boolean;
  company: {
    id: number;
    name: string;
    cohortMembership: string[];
  };
  isFormerEmployee: boolean;
  displayOrder?: number;
  tags: UserTags[];
  isCompanyAdmin: boolean;
}

export interface ActivityItem {
  id: number;
  type: string;
  date: string;
  dateTime: string;
  comment?: string;
}

export enum CohortMembership {
  mti2013 = "2013",
  mti2014 = "2014",
  mti2015 = "2015",
  mti2016 = "2016",
  mti2017 = "2017",
  mti2018 = "2018",
  mti2019 = "2019",
  mti2020 = "2020",
  mti2021 = "2021",
  mti2022 = "2022",
  mti2023 = "2023",
  mti2024 = "2024",
  bti2021 = "biotools2021",
  bti2022 = "biotools2022",
  bti2023 = "biotools2023",
  bti2024 = "biotools2024",
  apac2019 = "apac2019",
  apac2020 = "apac2020",
  apac2021 = "apac2021",
  apac2022 = "apac2022",
  apac2023 = "apac2023",
  apac2024 = "apac2024",
  peds2019 = "peds2019",
  peds2020 = "peds2020",
  peds2021 = "peds2021",
  peds2022 = "peds2022",
  peds2023 = "peds2023",
  usMarketAccess2024 = "usMarketAccess2024",
}

export enum FeedbackLevel {
  Weak = "Weak",
  Good = "Good",
  Strong = "Strong",
}

export enum Recommendation {
  Yes = "Yes",
  No = "No",
}

export interface Company {
  id: number;
  created: string;
  updated: string;
  deletedAt: string;
  name: string;
  elevatorPitch: string;
  cleanName: string;
  website: string;
  city: string;
  state: string;
  yearFounded: number;
  runway: string;
  location: string;
  primaryUserId: number;
  primaryProductId: number;
  country: string;
  street1: string;
  street2: string;
  zipcode: string;
  phone: string;
  isSponsor: boolean;
  abbreviation: string;
  isReviewer: boolean;
  typeAbbreviation: string;
  programAccess: string;
  fullTimeHeadcount: number;
  partTimeHeadcount: number;
  programSponsor: string;
  programVoting: string;
  linkedInUrl: string;
  developmentStage: string;
  regulatoryPathway: string;
  cohortMembership: string[];
  hubSpotRecordId: string;
  shareDealsWithInvestors: boolean;
  shareDealsWithPortfolio: boolean;
  logoUrl: string;
  lastUpdatedBy: User;
  users: User[];
  fundingContact: User;
  milestones: Milestone[];
  fundingToDate: number;
  equityToDate: number;
  mostRecentApplicationId: number;
  equity: string;
  debtConvertibleNote: string;
  nonDilutiveFunding: string;
  primaryProduct: Product;
  fundingContactId?: number;
  companyLists: any;
}

export interface Application {
  id: number;
  created: string;
  updated: string;
  deletedAt: string;
  teamExpertise: string;
  anticipatedClosing: string;
  milestonesAchievable: string;
  milestonesAccomplished: string;
  proceeds: string;
  generatingRevenueYesNo: string;
  revenueModelSelect?: string;
  healthcareEconomics: string;
  seeking: string;
  fundingSeriesSelect: string;
  grantsToDate: string;
  equityToDate: string;
  competitionsList: string;
  notableAwards: string;
  keyAccomplishments: string;
  medicalAreaPrimary: string;
  runway: string;
}

export enum VideoType {
  // Competition = "competition",
  // Evidence = "evidence",
  // InfoSession = "infoSession",
  // Interview = "interview",
  Lightning = "lightning",
  Marketing = "marketing",
  Pitch = "pitch",
  // Testimonial = "testimonial",
  // Webinar = "webinar",
  // Other = "other",
}

export const VideoTypeOptions = [
  { value: VideoType.Lightning, label: "Lightning Pitch" },
  { value: VideoType.Marketing, label: "Marketing" },
  { value: VideoType.Pitch, label: "Pitch Event" },
];

export const NewVideoTypeOptions = [
  { value: VideoType.Marketing, label: "Marketing" },
];

export interface Video {
  id: number;
  url: string;
  youtubeVideoId: string;
  created: string;
  updated: string;
  deletedAt?: string;
  title: string;
  description: string;
  companyId?: number | null;
  companyName?: string | null;
  duration?: number;
  viewCount?: number;
  type?: VideoType | null;
  lastUpdatedBy?: User;
  company: Company;
  productId?: number | null;
  isVisible: boolean | null;
}

export enum NotificationFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  None = "None",
}

export enum AlertContent {
  NewDeal = "New deal matching your filter criteria",
  ChangesToDealAmountOrValuations = "Deal amount changed",
  ChangesToProjectedCloseDate = "Changes to close date",
  DealStatusChanged = "Deal status changed (completed/failed)",
  MilestonesAddedOrUpdated = "Milestones added or updated",
}

export interface Milestone {
  id?: number;
  created?: string;
  updated: string;
  deletedAt?: string;
  name: string;
  startDate?: string;
  completedDate?: string;
  companyId?: number;
  productId?: number;
  status?: string;
  funding?: milestoneFunding;
  category?: milestoneCategories;
  isFunding?: boolean;
  isHighlight?: boolean;
  expanded?: boolean;
  hasTimeDuration?: boolean;
  date?: string;
  displayDate?: string;
  detail?: string;
  lastUpdatedBy?: User;
  product?: Product;
}

export interface Investor {
  id: number;
  created: string;
  updated: string;
  deletedAt: string | null;
  name: string;
}

export interface DealToInvestor {
  created: string;
  updated: string;
  deletedAt: string | null;
  dealId: number;
  investorId: number;
  isLead: boolean;
  investor: Investor;
}

export enum dealFundingStatus {
  inProgress = "In Progress",
  completed = "Completed",
  failedCancelled = "Failed/Cancelled",
}

export interface Deal {
  id: number;
  updated: string;
  lastUpdatedById: string;
  lastUpdatedBy: User;
  companyId: number;
  airTableId: string;
  amount: number;
  openAmount: number;
  closedAmount: number;
  committedAmount: number;
  amountStatus: string;
  clinicalCategories: any[];
  createdTime: string;
  closeDate: string;
  type: DealTypes;
  round: string;
  deviceCategories: any[];
  equityPostMTI: number;
  fundingStatus?: dealFundingStatus;
  leadClinicalCategory: any[];
  location: string;
  overallCategories: any[];
  programYear: string;
  postVal: number;
  preVal: number;
  pps: number;
  productDevelopmentStage: string;
  program: string;
  regulatoryPathway: string;
  source: string;
  public: boolean;
  company: Company;
  dealToInvestors: DealToInvestor[];
  isPristine: boolean;
  hasTermSheet: boolean;
  hasLead: boolean;
  isPrivate?: boolean;
  isPublished: boolean;
  highlight?: string;
  urgency?: string;
  usRegulatoryStatus?: string;
  projectedRunway?: string;
  runway?: string;
  previousInvestors: string[];
  watchListed?: boolean;

  isNew?: boolean;
  //Risk Filter placeholders:
  revenue: string;
  regulatoryStatus: string;
  evidenceGatheringStatus: string;
  team: string;
  finalFundraiser: boolean;
}

export const blankDeal = {
  id: -1,
  updated: "",
  lastUpdatedById: "",
  previousInvestors: [],
  preVal: null,
  postVal: null,
  amount: null,
  openAmount: null,
  closedAmount: null,
  committedAmount: null,
  companyId: 0,
  date: "",
  dealToInvestors: [],
  public: false,
  company: {} as Company,
  isPristine: true,
  airTableId: "",
  amountStatus: "",
  clinicalCategories: [],
  createdTime: "",
  deviceCategories: [],
  equityPostMTI: 0,
  leadClinicalCategory: [],
  location: "",
  overallCategories: [],
  programYear: "",
  pps: 0,
  productDevelopmentStage: "",
  program: "",
  regulatoryPathway: "",
  source: "",
  lastUpdatedBy: {} as User,
  isPrivate: false,
  isPublished: false,
  hasTermSheet: false,
  hasLead: false,
  round: "",
  revenue: "",
  regulatoryStatus: "",
  evidenceGatheringStatus: "",
  team: "",
  finalFundraiser: false,
  investors: [],
};

export interface FilterPill {
  category: string;
  name: string;
  numberOptions: number;
  numberSelectedOptions: number;
}

export interface InvestorFilter {
  id: number;
  name: string;
  description: string;
  hasLead: string;
  productDevelopmentStage: string[];
  regulatoryPathway: string[];
  regulatoryStatus: string[];
  alertContents: AlertContent[];
  notificationFrequency: NotificationFrequency;
  selectedListId: string[];
  searchTerm: string;
  clinicalAreas: string[];
  dealType: string[];
  amountMin: string;
  amountMax: string;
  type: string[];
  alerts: boolean;
  watchListed: boolean;
  emailRecipients: string[];
  startDate: string;
  endDate: string;
}

export enum milestoneStatuses {
  completed = "Completed",
  inProgress = "In-Progress",
  upcoming = "Upcoming",
}

export const milestoneStatusOptions = [
  { value: milestoneStatuses.completed, label: "Completed" },
  { value: milestoneStatuses.inProgress, label: "In-Progress" },
  { value: milestoneStatuses.upcoming, label: "Upcoming" },
];

export enum milestoneFunding {
  Completed = "completed",
  Current = "current",
  OpenRound = "openRound",
  FutureRound = "futureRound",
}

export const milestoneFundingOptions = [
  {
    value: milestoneFunding.Completed,
    label: "Milestones already accomplished",
  },
  {
    value: milestoneFunding.Current,
    label: "Milestones achievable with your current funding / resources",
  },
  {
    value: milestoneFunding.OpenRound,
    label: "Milestones that your open round will achieve",
  },
  {
    value: milestoneFunding.FutureRound,
    label: "Milestones that a future round will achieve",
  },
];

export enum milestoneCategories {
  Clinical = "clinical",
  EvidenceGeneration = "evidence",
  Financial = "financial",
  ProductDevelopment = "product",
  Regulatory = "regulatory",
  ReimbursementPayment = "reimbursement",
  Traction = "traction",
}

export const milestoneCategoriesOptions = [
  { value: milestoneCategories.Clinical, label: "Clinical" },
  {
    value: milestoneCategories.EvidenceGeneration,
    label: "Evidence Generation (Pre-clinical and Clinical)",
  },
  { value: milestoneCategories.Financial, label: "Financial" },
  { value: milestoneCategories.Regulatory, label: "Regulatory" },
  {
    value: milestoneCategories.ProductDevelopment,
    label: "Product Development",
  },
  {
    value: milestoneCategories.ReimbursementPayment,
    label: "Reimbursement / Payment",
  },
  {
    value: milestoneCategories.Traction,
    label: "Traction (# Patients, # Procedures, # Customers)",
  },
];

export enum ProductDevelopmentStage {
  concept = "Concept",
  marketReady = "Market Ready",
  payingCustomers = "Paying Customers",
  productDeveloped = "Product Developed",
  productDevelopedBetaTesting = "Product Developed - Beta Testing",
  productDevelopedPreApproval = "Product Developed - Pre-Approval",
  prototypeDeveloped = "Prototype Developed",
  prototypeDevelopedClinical = "Prototype Developed - Clinical",
  prototypeDevelopedPreClinical = "Prototype Developed - Pre-Clinical",
}

export enum ProductUsRegulatoryStatus {
  applicationFiledUs = "Application Filed",
  fdaApproved = "FDA Approved",
  fdaPreSubmissionMeetingCompleted = "Had FDA Pre-Submission Meeting",
  notApplicable = "Not Applicable",
  notStarted = "Not Started",
  preparingForSubmission = "Preparing for Submission",
  preparingSubmissionUs = "Preparing Submission",
  scheduledFdaPreSubmissionMeeting = "Scheduled FDA Pre-Submission Meeting",
}

export const ProductUsRegulatoryStatusOptions = [
  {
    value: ProductUsRegulatoryStatus.notStarted,
    label: "Not Started",
  },
  {
    value: ProductUsRegulatoryStatus.notApplicable,
    label: "Not Applicable",
  },
  {
    value: ProductUsRegulatoryStatus.scheduledFdaPreSubmissionMeeting,
    label: "Scheduled FDA Pre-Submission Meeting",
  },
  {
    value: ProductUsRegulatoryStatus.fdaPreSubmissionMeetingCompleted,
    label: "Had FDA Pre-Submission Meeting",
  },
  {
    value: ProductUsRegulatoryStatus.preparingSubmissionUs,
    label: "Preparing Submission",
  },
  {
    value: ProductUsRegulatoryStatus.applicationFiledUs,
    label: "Application Filed",
  },
  {
    value: ProductUsRegulatoryStatus.fdaApproved,
    label: "FDA Approved",
  },
];

export enum ProductUsRegulatoryPathway {
  fiveHundredTenK = "510(k)",
  deNovo = "De Novo",
  pma = "PMA",
  combinationProductCDRH = "Combination Product (CDRH)",
  combinationProductCBER = "Combination Product (CBER)",
}

export enum ProductEURegulatoryStatus {
  notStartedOrApplicable = "Not Started or Applicable",
  preparingSubmissionEu = "Preparing Submission",
  applicationFiledEu = "Application Filed",
  ceMarked = "CE Marked",
}

export enum ProductAsiaRegulatoryStatus {
  preparingSubmissionAsia = "Preparing Submission",
  applicationFiledAsia = "Application Filed",
  registeredAsia = "Registered",
}

export const ProductEURegulatoryStatusOptions = [
  {
    value: ProductEURegulatoryStatus.notStartedOrApplicable,
    label: "Not Started or Applicable",
  },
  {
    value: ProductEURegulatoryStatus.preparingSubmissionEu,
    label: "Preparing Submission",
  },
  {
    value: ProductEURegulatoryStatus.applicationFiledEu,
    label: "Application Filed",
  },
  {
    value: ProductEURegulatoryStatus.ceMarked,
    label: "CE Marked",
  },
];

export interface Product {
  id: number;
  name: string;
  summary: string;
  elevatorPitch: string;
  developmentStage: ProductDevelopmentStage | string | null;
  files: InnovatorFile[];
  regulatoryPathway: string;
  usRegulatoryStatus: string;
  europeRegulatoryStatus: string;
  asiaRegulatoryStatus: string;
  asiaRegulatoryDetails: string;
  isPrimaryProduct: boolean;
  companyId: number;
  productCategories: string[];
  clinicalCategories: string[];
  primaryClinicalCategory?: string;
  updated: string;
  fdaClassification?: string;
  breakthroughDesignation?: string;
  lastUpdatedBy: User;
}

export const emptyPortalProduct: Product = {
  name: "",
  summary: "",
  updated: "",
  elevatorPitch: "",
  developmentStage: null,
  files: [],
  regulatoryPathway: "",
  usRegulatoryStatus: "",
  europeRegulatoryStatus: "",
  asiaRegulatoryStatus: "",
  asiaRegulatoryDetails: "",
  isPrimaryProduct: false,
  companyId: 0,
  lastUpdatedBy: {} as User,
  id: 0,
  productCategories: [],
  clinicalCategories: [],
};

export const productCategoryOptions = [
  { value: "Diagnostic", label: "Diagnostic" },
  { value: "Digital", label: "Digital Health" },
  { value: "Device", label: "Medical Device" },
  { value: "Equipment / Instruments", label: "Equipment / Instruments" },
  { value: "Services", label: "Services" },
  { value: "Reagents / Consumables", label: "Reagents / Consumables" },
  { value: "Software", label: "Software" },
];

export const clinicalCategoryOptions = [
  { value: "Allergy / Immunology", label: "Allergy / Immunology" },
  {
    value: "Anesthesiology / Critical Care Medicine",
    label: "Anesthesiology / Critical Care Medicine",
  },
  {
    value: "Cardiac / Thoracic / Vascular Surgery",
    label: "Cardiac / Thoracic / Vascular Surgery",
  },
  { value: "Cardiology", label: "Cardiology" },
  { value: "Colo / Rectal Surgery", label: "Colo / Rectal Surgery" },
  {
    value: "Dentistry / Orthodontics / Oral Surgery",
    label: "Dentistry / Orthodontics / Oral Surgery",
  },
  { value: "Dermatology", label: "Dermatology" },
  { value: "Emergency Medicine", label: "Emergency Medicine" },
  {
    value: "Endocrinology / Diabetes / Metabolism",
    label: "Endocrinology / Diabetes / Metabolism",
  },
  { value: "ENT", label: "ENT" },
  { value: "Gastroenterology", label: "Gastroenterology" },
  { value: "General Surgery", label: "General Surgery" },
  { value: "Infectious Disease", label: "Infectious Disease" },
  { value: "Interventional", label: "Interventional" },
  { value: "Nephrology", label: "Nephrology" },
  { value: "Neurological Surgery", label: "Neurological Surgery" },
  { value: "Neurology", label: "Neurology" },
  { value: "Obstetrics / Gynecology", label: "Obstetrics / Gynecology" },
  { value: "Oncology / Hematology", label: "Oncology / Hematology" },
  { value: "Ophthalmology", label: "Ophthalmology" },
  {
    value: "Orthopaedic Surgery / Sports Medicine",
    label: "Orthopaedic Surgery / Sports Medicine",
  },
  { value: "Pain Medicine", label: "Pain Medicine" },
  {
    value: "Pathology / Laboratory Medicine",
    label: "Pathology / Laboratory Medicine",
  },
  {
    value: "Physical Medicine / Rehabilitation",
    label: "Physical Medicine / Rehabilitation",
  },
  {
    value: "Plastic Surgery / Aesthetics",
    label: "Plastic Surgery / Aesthetics",
  },
  {
    value: "Preventive Medicine / Wellness",
    label: "Preventive Medicine / Wellness",
  },
  {
    value: "Psychiatry / Behavioral Health",
    label: "Psychiatry / Behavioral Health",
  },
  { value: "Pulmonary Disease", label: "Pulmonary Disease" },
  {
    value: "Radiology / Nuclear Medicine Rad Onc",
    label: "Radiology / Nuclear Medicine Rad Onc",
  },
  { value: "Rheumatology", label: "Rheumatology" },
  { value: "Sleep Medicine", label: "Sleep Medicine" },
  { value: "Urology", label: "Urology" },
];

export const primaryRevenueModelOptions = [
  { value: "Equipment", label: "Equipment / Capital Sales" },
  { value: "Fee", label: "Fee for service" },
  { value: "License", label: "Licensing" },
  { value: "Reagent", label: "Reagent / Consumable" },
  { value: "SAAS", label: "SAAS / Subscription Based" },
  { value: "Transaction", label: "Transaction Fee / Royalty" },
];

export const keyMilestones = [
  "Feasibility Prototype / POC",
  "Clinical Prototype",
  "Pre-Clinical Study",
  "First-in-Human Study",
  "Pivotal / Approval Study",
  "Marketing Study (Validation)",
];

export const BTIProductDevelopmentStageOptions = [
  {
    value: ProductDevelopmentStage.concept,
    label: "Concept",
    secondary: "No Prototype - Idea Stage",
  },
  {
    value: ProductDevelopmentStage.prototypeDeveloped,
    label: "Prototype Developed",
  },
  {
    value: ProductDevelopmentStage.productDeveloped,
    label: "Product Developed",
  },
  {
    value: ProductDevelopmentStage.productDevelopedBetaTesting,
    label: "Product Developed - Beta Testing",
  },
  {
    value: ProductDevelopmentStage.marketReady,
    label: "Market Ready",
  },
  {
    value: ProductDevelopmentStage.payingCustomers,
    label: "Paying Customers",
  },
];

export enum DealTypes {
  AcceleratorIncubator = "Accelerator/Incubator",
  Angel = "Angel (individual)",
  Capitalization = "Capitalization",
  ConvertibleDebt = "Convertible Debt",
  DebtGeneral = "Debt - General",
  DebtPPP = "Debt - PPP",
  EarlyStageVC = "Early Stage VC",
  EquityCrowdfunding = "Equity Crowdfunding",
  Grant = "Grant",
  IPO = "IPO",
  LaterStageVC = "Later Stage VC",
  MergerAcquisition = "Merger/Acquisition",
  PEGrowthExpansion = "PE Growth/Expansion",
  PIPE = "PIPE",
  ProductCrowdfunding = "Product Crowdfunding",
  SecondaryTransactionPrivate = "Secondary Transaction - Private",
  SeedRound = "Seed Round",
  Other = "Other",
  ConvertibleNote = "Convertible Note",
  SAFE = "SAFE",
  PricedRound = "Priced Round",
  Bridge = "Bridge",
  Corporate = "Corporate",
  JointVenture = "Joint Venture",
  PublicInvestment2ndOffering = "Public Investment 2nd Offering",
  ReverseMerger = "Reverse Merger",
}

export const openRoundDealTypes = [
  DealTypes.ConvertibleNote,
  DealTypes.SAFE,
  DealTypes.PricedRound,
  DealTypes.Other,
];

export const validEquityDealTypes = [
  DealTypes.LaterStageVC,
  DealTypes.EarlyStageVC,
  DealTypes.Angel,
  DealTypes.ConvertibleDebt,
  DealTypes.DebtGeneral,
  DealTypes.SeedRound,
  DealTypes.Capitalization,
  DealTypes.AcceleratorIncubator,
  DealTypes.SecondaryTransactionPrivate,
  DealTypes.Bridge,
  DealTypes.Corporate,
  DealTypes.EquityCrowdfunding,
  DealTypes.IPO,
  DealTypes.JointVenture,
  DealTypes.PEGrowthExpansion,
  DealTypes.PIPE,
  DealTypes.ProductCrowdfunding,
  DealTypes.PublicInvestment2ndOffering,
  DealTypes.ReverseMerger,
  DealTypes.SecondaryTransactionPrivate,
];

export const dealRounds = [
  "Pre-Seed",
  "Seed",
  "Other",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
  "Series A1",
  "Series A2",
  "Series A3",
  "Series AA",
  "Series B1",
  "Series B2",
  "Series B3",
  "Series BB",
  "Series C1",
  "Series C2",
  "Series C3",
] as const;

export enum UserTags {
  mtiMentor2024 = "mtiMentor2024",
  mtiMentor2023 = "mtiMentor2023",
  mtiMentor2022 = "mtiMentor2022",
  btiMentor2024 = "btiMentor2024",
  btiMentor2023 = "btiMentor2023",
  btiMentor2022 = "btiMentor2022",
  apacMentor2024 = "apacMentor2024",
  apacMentor2023 = "apacMentor2023",
  apacMentor2022 = "apacMentor2022",
  mtiJudge2024 = "mtiJudge2024",
  mtiJudge2023 = "mtiJudge2023",
  mtiJudge2022 = "mtiJudge2022",
  btiJudge2024 = "btiJudge2024",
  btiJudge2023 = "btiJudge2023",
  btiJudge2022 = "btiJudge2022",
  apacJudge2024 = "apacJudge2024",
  apacJudge2023 = "apacJudge2023",
  apacJudge2022 = "apacJudge2022",
  mtiReviewer2024 = "mtiReviewer2024",
  mtiReviewer2023 = "mtiReviewer2023",
  mtiReviewer2022 = "mtiReviewer2022",
  btiReviewer2024 = "btiReviewer2024",
  btiReviewer2023 = "btiReviewer2023",
  btiReviewer2022 = "btiReviewer2022",
  apacReviewer2024 = "apacReviewer2024",
  apacReviewer2023 = "apacReviewer2023",
  apacReviewer2022 = "apacReviewer2022",
  mtiPartner2024 = "mtiPartner2024",
  mtiPartner2023 = "mtiPartner2023",
  mtiPartner2022 = "mtiPartner2022",
  btiPartner2024 = "btiPartner2024",
  btiPartner2023 = "btiPartner2023",
  btiPartner2022 = "btiPartner2022",
  apacPartner2024 = "apacPartner2024",
  apacPartner2023 = "apacPartner2023",
  apacPartner2022 = "apacPartner2022",
  "Pitch Judge" = "Pitch Judge",
  "Mentor" = "Mentor",
  "Partner" = "Partner",
  "Reviewer" = "Reviewer",
  clinicalTrials = "clinicalTrials",
  fundraising = "fundraising",
  operationsAndManagement = "operationsAndManagement",
  paymentAndReimbursement = "paymentAndReimbursement",
  productDevelopment = "productDevelopment",
  regulatory = "regulatory",
  sales = "sales",
  hiring = "hiring",
  finance = "finance",
  investor = "investor",
  marketing = "marketing",
  medicalDevice = "medicalDevice",
  diagnostic = "diagnostic",
  digitalHealth = "digitalHealth",
  bioTools = "bioTools",
}

export const AllTagOptions = [
  { value: UserTags.apacJudge2022, label: "APAC Judge 2022" },
  { value: UserTags.apacJudge2023, label: "APAC Judge 2023" },
  { value: UserTags.apacJudge2024, label: "APAC Judge 2024" },
  { value: UserTags.apacMentor2022, label: "APAC Mentor 2022" },
  { value: UserTags.apacMentor2023, label: "APAC Mentor 2023" },
  { value: UserTags.apacMentor2024, label: "APAC Mentor 2024" },
  { value: UserTags.apacPartner2022, label: "APAC Partner 2022" },
  { value: UserTags.apacPartner2023, label: "APAC Partner 2023" },
  { value: UserTags.apacPartner2024, label: "APAC Partner 2024" },
  { value: UserTags.apacReviewer2022, label: "APAC Reviewer 2022" },
  { value: UserTags.apacReviewer2023, label: "APAC Reviewer 2023" },
  { value: UserTags.apacReviewer2024, label: "APAC Reviewer 2024" },
  { value: UserTags.btiJudge2022, label: "BTI Judge 2022" },
  { value: UserTags.btiJudge2023, label: "BTI Judge 2023" },
  { value: UserTags.btiJudge2024, label: "BTI Judge 2024" },
  { value: UserTags.btiMentor2022, label: "BTI Mentor 2022" },
  { value: UserTags.btiMentor2023, label: "BTI Mentor 2023" },
  { value: UserTags.btiMentor2024, label: "BTI Mentor 2024" },
  { value: UserTags.btiPartner2022, label: "BTI Partner 2022" },
  { value: UserTags.btiPartner2023, label: "BTI Partner 2023" },
  { value: UserTags.btiPartner2024, label: "BTI Partner 2024" },
  { value: UserTags.btiReviewer2022, label: "BTI Reviewer 2022" },
  { value: UserTags.btiReviewer2023, label: "BTI Reviewer 2023" },
  { value: UserTags.btiReviewer2024, label: "BTI Reviewer 2024" },
  { value: UserTags.mtiJudge2022, label: "MTI Judge 2022" },
  { value: UserTags.mtiJudge2023, label: "MTI Judge 2023" },
  { value: UserTags.mtiJudge2024, label: "MTI Judge 2024" },
  { value: UserTags.mtiMentor2022, label: "MTI Mentor 2022" },
  { value: UserTags.mtiMentor2023, label: "MTI Mentor 2023" },
  { value: UserTags.mtiMentor2024, label: "MTI Mentor 2024" },
  { value: UserTags.mtiPartner2022, label: "MTI Partner 2022" },
  { value: UserTags.mtiPartner2023, label: "MTI Partner 2023" },
  { value: UserTags.mtiPartner2024, label: "MTI Partner 2024" },
  { value: UserTags.mtiReviewer2022, label: "MTI Reviewer 2022" },
  { value: UserTags.mtiReviewer2023, label: "MTI Reviewer 2023" },
  { value: UserTags.mtiReviewer2024, label: "MTI Reviewer 2024" },
  {
    value: UserTags.clinicalTrials,
    label: "Clinical Trials",
  },
  {
    value: UserTags.fundraising,
    label: "Fundraising",
  },
  {
    value: UserTags.operationsAndManagement,
    label: "Operations & Management",
  },
  {
    value: UserTags.paymentAndReimbursement,
    label: "Payment & Reimbursement",
  },
  {
    value: UserTags.productDevelopment,
    label: "Product Development",
  },
  {
    value: UserTags.regulatory,
    label: "Regulatory",
  },
  {
    value: UserTags.sales,
    label: "Sales",
  },
  {
    value: UserTags.hiring,
    label: "Hiring",
  },
  {
    value: UserTags.finance,
    label: "Finance",
  },
  {
    value: UserTags.investor,
    label: "Investor",
  },
  {
    value: UserTags.marketing,
    label: "Marketing",
  },
  {
    value: UserTags.medicalDevice,
    label: "Medical Device",
  },
  {
    value: UserTags.diagnostic,
    label: "Diagnostic",
  },
  {
    value: UserTags.digitalHealth,
    label: "Digital Health",
  },
  {
    value: UserTags.bioTools,
    label: "BioTools",
  },
];

export const expertiseOptions = [
  {
    value: UserTags.clinicalTrials,
    label: "Clinical Trials",
  },
  {
    value: UserTags.fundraising,
    label: "Fundraising",
  },
  {
    value: UserTags.operationsAndManagement,
    label: "Operations & Management",
  },
  {
    value: UserTags.paymentAndReimbursement,
    label: "Payment & Reimbursement",
  },
  {
    value: UserTags.productDevelopment,
    label: "Product Development",
  },
  {
    value: UserTags.regulatory,
    label: "Regulatory",
  },
  {
    value: UserTags.sales,
    label: "Sales",
  },
  {
    value: UserTags.hiring,
    label: "Hiring",
  },
  {
    value: UserTags.finance,
    label: "Finance",
  },
  {
    value: UserTags.investor,
    label: "Investor",
  },
  {
    value: UserTags.marketing,
    label: "Marketing",
  },
  {
    value: UserTags.medicalDevice,
    label: "Medical Device",
  },
  {
    value: UserTags.diagnostic,
    label: "Diagnostic",
  },
  {
    value: UserTags.digitalHealth,
    label: "Digital Health",
  },
  {
    value: UserTags.bioTools,
    label: "BioTools",
  },
];
export const ecosystemRoleOptions = [
  {
    value: UserTags["Pitch Judge"],
    label: "Pitch Judge",
  },
  {
    value: UserTags.Mentor,
    label: "Mentor",
  },
  {
    value: UserTags.Partner,
    label: "Partner",
  },
  {
    value: UserTags.Reviewer,
    label: "Reviewer",
  },
];
export interface CompanyAdmin {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface AccessRequest {
  id: number;
  userId: number;
  companyId: number;
  status: string;
  user: {
    firstName: string;
    lastName: string;
  };
  company: {
    name: string;
    admins: {
      firstName: string;
      lastName: string;
      email: string;
    }[];
  };
}

export interface DealView {
  viewedAt: string;
  dealId: number;
}
